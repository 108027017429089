import 'react-app-polyfill/stable';
import './index.css';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { setLogger } from '@optimizely/react-sdk';
import { Routes } from 'Routes';
import { env } from 'constants/env';
import { FullStory, Heap } from 'V2/Analytics';
import version from './version';

if (window.location.href.includes('toyota')) {
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${env.toyotaGoogleTagManagerId}`;

  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${env.toyotaGoogleTagManagerId}');
  `;

  document.head.appendChild(script2);
}

if (env.nodeEnv !== 'development') {
  Sentry.init({
    dsn: 'https://1bc82ffb33ae4027aca9c9e50bb452bf@o90268.ingest.sentry.io/5828859',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: `${env.clearcoverEnv}`,
    release: `acquisition-app@${version.git_sha}`,
    ignoreErrors: ['fp/tags', 'instantSearchSDKJSBridgeClearHighlight'],
  });
}

if (env.clearcoverEnv === 'production') {
  setLogger(null);
}

// Configure in Datadog console but customized:  see https://app.datadoghq.com/rum/application/6d49ee3a-3417-4f69-8fc0-0e4948cc7700/sdk
const datadogConfig = {
  clientToken: env.dataDogClientToken,
  site: 'datadoghq.com',
  env: env.clearcoverEnv,
  service: 'acquisition-app',
  version: env.nodeEnv === 'development' ? 'development' : version.git_sha,
};

datadogRum.init({
  applicationId: env.dataDogId,
  ...datadogConfig,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [env.apiHost],
});

datadogLogs.init({
  forwardErrorsToLogs: true,
  ...datadogConfig,
});

const heapConfig = {
  appId: env.heapId,
  enabled: env.heapEnabled === '1',
};

Heap.init(heapConfig);

const isRenderedInIframe = window !== window.top;
const fullStoryConfig = {
  appId: env.fullstoryId,
  enabled: env.fullstoryEnabled === '1',
  isOuterScript: isRenderedInIframe,
};

FullStory.init(fullStoryConfig);
FullStory.onReady(function (fs) {
  Heap.track('FullStory Session', {
    'Fullstory Session URL': fs.getCurrentSessionURL(true),
  });
  Heap.addUserProperties({
    'Latest FullStory Session': fs.getCurrentSessionURL(),
  });
});

const Application = () => (
  <Router>
    <Routes />
  </Router>
);

render(<Application />, document.getElementById('root'));
