import { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TransitionDuration } from 'V2/Shared/Animations/Presets';
import {
  CommandType,
  Nullable,
  ValidationType,
  SchemaType,
  SummaryType,
  StepConfigType,
  EntityType,
  DescriptionType,
} from 'V2/Types';
import {
  StepResolveType,
  StepFieldType,
  buildFields,
  buildFieldErrors,
  buildFormValues,
  buildSchema,
} from 'V2/Step';
import { useApi } from 'V2/Api';
import { useApplyProvider } from './ApplyProvider';
import {
  SubmitType,
  ValidateType,
  UseFeaturesType,
  UseQuoteChangeAcknowledgementType,
} from './types';

const validationDefault = {
  description: null,
  field_data: [],
  label: '',
  type: '',
  validation_choices: [],
};
export const useSubmit = () => {
  const history = useHistory();
  const { postResource, flowSequence } = useApi();
  const { setApp, setIsAnimating } = useApplyProvider();

  const [validation, setValidation] =
    useState<ValidationType>(validationDefault);

  const resetValidation = () => setValidation(validationDefault);

  const setStep = (
    { status, config, summary }: StepResolveType,
    omitRouting?: boolean
  ) => {
    // don't set config until the step animates out;
    // this fixes any bugs where a step animating out
    // displays step configuration for an incoming step
    setTimeout(() => {
      setApp((app) => ({
        ...app,
        status,
        config,
        summary,
        fields: buildFields(status.field_data, config),
      }));

      setIsAnimating(false);

      // introduced omitRouting here becuase sometimes
      // we want to set the set and data without routing;
      // like in the edit mode scenario
      !omitRouting &&
        history.push({
          pathname: `/apply/${status.id}`,
          search: '',
        });
    }, TransitionDuration.milliseconds);
  };

  const submit: SubmitType = (url, payload, formActions) =>
    new Promise((resolve, reject) => {
      postResource({
        url,
        data: {
          field_data: payload,
        },
      })
        .then(() => {
          flowSequence()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              setIsAnimating(false);
              reject(error);
            });
        })
        .catch((error) => {
          setIsAnimating(false);
          reject(error);
        });
    });

  const validate: ValidateType = (url, payload, formActions): Promise<void> => {
    // reset apiErrors
    formActions && formActions.setStatus(undefined);

    setIsAnimating(true);

    return new Promise((resolve) => {
      postResource({
        url,
        data: {
          field_data: payload,
        },
      })
        .then((response: { data: ValidationType }) => {
          switch (response.data.type) {
            case 'invalid': {
              setIsAnimating(false);

              formActions &&
                formActions.setStatus({
                  apiErrors: {
                    errors: buildFieldErrors(response.data.field_data),
                    data: response.data,
                  },
                });
              break;
            }

            case 'warning': {
              setIsAnimating(false);
              setValidation(response.data);
              break;
            }

            case 'success':
            default: {
              resolve();
            }
          }
        })
        .catch(() => {
          setIsAnimating(false);
        });
    });
  };

  return {
    setStep,
    validate,
    submit,
    validation,
    resetValidation,
  };
};

export const usePolling = (submitUri: string, fields: StepFieldType[]) => {
  const { setApp } = useApplyProvider();
  const history = useHistory();
  const { submit, setStep } = useSubmit();
  const { getResource, flowSequence, getStatus } = useApi();

  const field = fields[0];
  const isStartedState = useState(false);
  const setIsStarted = isStartedState[1];
  const [pollStatus, setPollStatus] = useState<Nullable<string>>(
    field.entity_type.sub_type
  );

  useEffect(() => {
    let attempts = 60;

    const poll = () => {
      if (attempts >= 1) {
        const randomMillesecond = Math.random() * 100;

        getStatus()
          .then((response) => {
            const currentField = response.data.field_data.find(
              ({ entity_type: { id } }) => id === 'system_process'
            );

            switch (currentField && currentField.entity_type.sub_type) {
              // continue to get status since you are in pending mode
              case 'pending': {
                setTimeout(() => {
                  attempts--;
                  poll();
                }, 1000 + randomMillesecond);

                break;
              }
              // something happened with background jobs; we want to send the user
              // to the error screen for clear analytics/reporting and fallback
              case 'complete_with_error': {
                history.push('/error?errorType=complete_with_error');

                break;
              }
              // any other sub_type, we know the job is complete so we continue
              // to get config/summary and set the step to move forward
              default: {
                flowSequence().then((response) => {
                  setPollStatus(null);

                  setStep({
                    status: response.status,
                    config: response.config,
                    summary: response.summary,
                  });
                });
              }
            }
          })
          .catch((e) => {
            history.push('/error?errorType=pollingServerError');
          });
      } else {
        // given a long time has passed, route to error on timeout
        history.push('/error?errorType=pollingTimeout');
      }
    };

    setIsStarted((isStarted) => {
      if (!isStarted) {
        if (pollStatus === 'unstarted') {
          submit(submitUri, [field]).then(poll);
        } else if (pollStatus === 'pending' || pollStatus === 'complete') {
          poll();
        }

        return true;
      } else {
        return isStarted;
      }
    });
  }, [
    field,
    pollStatus,
    history,
    setIsStarted,
    setApp,
    submit,
    setStep,
    getResource,
    flowSequence,
    getStatus,
    submitUri,
  ]);
};

export const useQuoteChangeAcknowledgement: UseQuoteChangeAcknowledgementType =
  () => {
    const { app, setApp } = useApplyProvider();
    const { postResource } = useApi();

    const { required, submit_uri, translations } =
      app?.status?.quote_change_acknowledgement || {};

    const acknowledgeQuoteChange = useCallback(() => {
      postResource({
        url: submit_uri,
      });
      // Optimistically close popup
      return setApp((app) => ({
        ...app,
        status: {
          ...app.status,
          quote_change_acknowledgement: {
            ...app.status.quote_change_acknowledgement,
            required: false,
          },
        },
      }));
    }, [submit_uri, setApp, postResource]);

    return {
      translations,
      acknowledgementRequired: required,
      acknowledgeQuoteChange,
    };
  };

export const useSummary = () => {
  const {
    app: {
      appId,
      summary: { entities, commands },
    },
  } = useApplyProvider();

  const drivers = useMemo(
    () => entities.filter((e) => e.type.id === 'driver'),
    [entities]
  );
  const vehicles = useMemo(
    () => entities.filter((e) => e.type.id === 'vehicle'),
    [entities]
  );

  const vehicleDriver = useMemo(
    () => entities.filter((e) => e.type.id === 'vehicle_driver'),
    [entities]
  );

  const coverages = useMemo(
    () => entities.filter((e) => e.type.id === 'coverage'),
    [entities]
  );
  const rates = useMemo(
    () => entities.filter((e) => e.type.id === 'rate'),
    [entities]
  );
  const accidents = useMemo(
    () => entities.filter((e) => e.type.id === 'accident'),
    [entities]
  );
  const violations = useMemo(
    () => entities.filter((e) => e.type.id === 'violation'),
    [entities]
  );
  const policy = useMemo(
    () => entities.find((e) => e.type.id === 'policy'),
    [entities]
  );
  const paymentPlan = useMemo(
    () => entities.find((e) => e.type.id === 'payment_plan'),
    [entities]
  );
  const rate = useMemo(
    () => entities.find((e) => e.type.id === 'rate'),
    [entities]
  );

  const getDriverIndex = useCallback(
    (entityId) => drivers.findIndex((driver) => driver.id === entityId),
    [drivers]
  );

  const getVehicleIndex = useCallback(
    (vehicleId) => vehicles.findIndex((vehicle) => vehicle.id === vehicleId),
    [vehicles]
  );

  return {
    appId,
    drivers,
    vehicles,
    coverages,
    rates,
    rate,
    accidents,
    violations,
    policy,
    paymentPlan,
    commands,
    vehicleDriver,
    getDriverIndex,
    getVehicleIndex,
  };
};

export const useFormProps = (fields: StepFieldType[], schema: SchemaType) => {
  const validationSchema = useMemo(
    () => buildSchema(fields, schema),
    [fields, schema]
  );
  const initialValues = useMemo(() => buildFormValues(fields), [fields]);

  return {
    initialValues,
    validationSchema,
    validateOnMount: true,
    isInitialValid: validationSchema.isValidSync(initialValues),
  };
};

export const useFields = (stepFields: StepFieldType[], fieldIds: string[]) =>
  fieldIds.reduce(
    (fields: { [key: string]: StepFieldType }, fieldId: string) => {
      const field = stepFields.find((f) => f.field_id === fieldId);

      return {
        ...fields,
        ...(field ? { [field.field_id]: field } : {}),
      };
    },
    {}
  );

// uses command and checks if summary should  be called
// or is status should be called based on parent_entity_id
export const useUpdateWithParentEntity = () => {
  const [isUpdating, setIsUpdating] = useState<Nullable<string>>(null);
  const resetIsUpdating = () => setIsUpdating(null);

  const { app, setApp } = useApplyProvider();
  const { setStep } = useSubmit();
  const { postResource, getResource, flowSequence } = useApi();

  const update = (
    entity: EntityType,
    command: CommandType,
    parentEntityId: string
  ) => {
    setIsUpdating(entity.id);

    postResource({ url: command.uri })
      .then(() => {
        getResource({ url: app.status.summary_uri })
          .then((response: { data: SummaryType }) => {
            const entities = response.data.entities
              .filter((e) => e.type.id === entity.type.id)
              .filter((e) => e.parent_entity_id === parentEntityId);

            if (entities.length === 0) {
              flowSequence().then(setStep).catch(resetIsUpdating);
            } else {
              setApp({
                ...app,
                summary: response.data,
              });

              resetIsUpdating();
            }
          })
          .catch(resetIsUpdating);
      })
      .catch(resetIsUpdating);
  };

  return {
    update,
    isUpdating,
  };
};

// uses command and updates application status
export const useUpdateEntityStatus = () => {
  const [isUpdating, setIsUpdating] = useState<Nullable<string>>(null);
  const resetIsUpdating = () => setIsUpdating(null);

  const { postResource, flowSequence } = useApi();
  const { setStep } = useSubmit();

  const update = (command: CommandType) => {
    setIsUpdating(command.id);

    postResource({ url: command.uri })
      .then(() => {
        flowSequence().then(setStep).catch(resetIsUpdating);
      })
      .catch(resetIsUpdating);
  };

  return {
    update,
    isUpdating,
  };
};

export const useFocusEntity = (summary: SummaryType, config: StepConfigType) =>
  useMemo(
    () =>
      summary.entities.find(
        (entity) => entity.id === config.application_focus.path.identifier
      ),
    [summary, config]
  );

/*
we are reducing features into a easier digestible manner; eventually,
the backend could send features in this manner as we've discussed a bit;
the caveat here is that we reduce 1 level down when features is recursive,
which is totally fine for now;

input => [
 {
   id: 'cta_button',
   value: null,
   features: [
     {
       id: 'uri',
       value: 'test.com',
       features: [],
     },
     {
       id: 'label',
       value: 'Click Me',
       features: [],
     }
   ]
 },
 {
   id: 'foo',
   value: true,
   features: [],
 }
]

output => {
 cta_button: {
   uri: 'test.com',
   label: 'Click Me',
 },
 foo: {
   value: true,
 },
 ...
}
*/
export const useFeatures: UseFeaturesType = (features, featureIds) =>
  useMemo(
    () =>
      featureIds.reduce((values, id) => {
        const feature = features.find((f) => f.id === id);

        return {
          ...values,
          ...(feature
            ? {
                [feature.id]: {
                  value: feature.value,
                  ...feature.features.reduce(
                    (childFeatures, f) => ({
                      ...childFeatures,
                      [f.id]: f.value,
                    }),
                    {}
                  ),
                },
              }
            : {}),
        };
      }, {}),
    [features, featureIds]
  );

type DescriptionObjectType = Partial<{ [key: string]: DescriptionType }>;
type UseStepDescriptionsType = () => DescriptionObjectType;

// For use with descriptions with unique ids. Not for use with disclaimer/helper descriptions
export const useStepDescriptions: UseStepDescriptionsType = () => {
  const {
    app: {
      config: { step_descriptions },
    },
  } = useApplyProvider();
  let initialValue: DescriptionObjectType = {};

  return step_descriptions.reduce((acc, val) => {
    // camelCase
    const key = val.type.id.replace(/(_\w)/g, (k) => k[1].toUpperCase());

    acc[key] = val;
    return acc;
  }, initialValue);
};
