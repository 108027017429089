import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './styles.module.css';
import close from '../Assets/close.svg';
import { Title } from './Content';

export type ModalConfigType = {
  showModal: boolean;
  title?: string;
  width?: number | string;
  badge?: string;
  closeAction: () => void;
};

export const Modal: React.FC<ModalConfigType> = ({
  showModal,
  children,
  title,
  width = '455px',
  badge,
  closeAction,
}) => {
  const renderBadge = () => {
    return (
      <div className={styles.badge}>
        <span>{badge}</span>
      </div>
    );
  };

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          key="modal-animation"
          initial={'out'}
          animate={'in'}
          exit={'out'}
          variants={{
            in: {
              transition: {
                staggerDirection: 1,
                staggerChildren: 0.1,
              },
            },
            out: {
              transition: {
                staggerDirection: -1,
                staggerChildren: 0.1,
              },
            },
          }}
          className={styles.main}
        >
          <motion.div
            variants={{
              in: { opacity: 1 },
              out: { opacity: 0 },
            }}
            transition={{ duration: 0.25 }}
            className={styles.overlay}
            onClick={closeAction}
          />
          <motion.div className={styles.wrapper} style={{ width }}>
            <motion.div
              variants={{
                in: { y: 0, opacity: 1 },
                out: { y: 40, opacity: 0 },
              }}
              transition={{ duration: 0.25 }}
              className={styles.container}
              data-testid="modal"
            >
              {title && (
                <div className={styles.titleRowContainer}>
                  {!!badge && renderBadge()}
                  <Title>{title}</Title>
                  <button
                    type={'button'}
                    className={styles.close}
                    onClick={closeAction}
                  >
                    <img height={14} width={14} src={close} alt="close" />
                  </button>
                </div>
              )}
              {children}
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
